<script>
import Layout from '@/Components/Layout.vue'

export default {
  // Using a render function...
  layout: (h, page) => h(Layout, [page]),

  // Using shorthand syntax...
  layout: Layout,
}
</script>

<script setup>
import { onMounted, ref } from 'vue'
import { initializePaddle } from '@paddle/paddle-js';
import { router } from '@inertiajs/vue3'

const props = defineProps({
  user: Object,
  account: Object,
  total_requests: Number,
  paddle_client_token: String,
  paddle_customer_id: String,
  paddle_price_id: String,
  paddle_environment: String
})

const paddle = ref(null)

const openSubscription = () => {
  paddle.value.Update({
    pwCustomer: {
      id: props.paddle_customer_id
    }
  })
  paddle.value.Checkout.open({
    items: [{
      priceId: props.paddle_price_id,
      quantity: 1
    }]
  })
}

onMounted(() => {
  initializePaddle({
    token: props.paddle_client_token,
    environment: props.paddle_environment
  }).then((paddleInstance) => {
    paddle.value = paddleInstance
  })

  router.reload({ only: ['total_requests'] })
})
</script>
<template>

  <div class="flex justify-center flex-col items-center">
    <h1 class="text-2xl font-black mb-4">
      Welcome, {{user.name}}!
    </h1>
    <p class="text-slate-700 text-lg mt-1 text-left">
      please use the below token to authenticate your requests.
    </p>
    <code class="text-slate-900 bg-gray-100 px-4 py-2 border text-lg mt-1">
      {{account.token}}
    </code>
      <p class="text-slate-700 text-lg mt-1" v-if="total_requests > 0">
        You have made {{total_requests}} requests so far.
      </p>
    <p class="text-slate-700 text-lg mt-1" v-else>
      You haven't made any requests yet.
    </p>
    <p class="mt-8 text-sm">
      👋 You can get on a call with us if you need help in integrating Limiter with your application.
      <a href="https://cal.com/bilalbudhani/hello" class="underline text-slate-900 font-medium">Schedule a call</a>
    </p>

    <button @click="openSubscription">Subscription</button>
  </div>

</template>