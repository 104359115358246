<script setup>
  import { ref, onMounted } from 'vue'
  const csrfToken = ref(null)

  onMounted(() => {
    csrfToken.value = document.querySelector('meta[name="csrf-token"]').content
  })
</script>

<template>
  <main class="w-100 h-screen flex justify-center items-center flex-col">
    <div class="px-16 py-8 bg-white rounded border flex justify-center flex-col items-center">
      <h1 class="text-2xl font-black">
        Limiter.dev
      </h1>
      <p class="text-slate-700 text-lg mt-1">
        Limiter is a simple rate limit checker for third party APIs
      </p>
      <form action="/users/auth/github" method="post" class="mt-8 text-center">
        <input type="hidden" name="authenticity_token" :value="csrfToken">
        <button class="bg-slate-900 text-white rounded px-6 py-2">Login via Github</button>
      </form>
    </div>
    <footer class="text-slate-700 py-4 flex items-center">
      <a href="https://go.limiter.dev/docs">Need Help?</a>
    </footer>
  </main>
</template>
